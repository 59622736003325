<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4 6.848H7.104V11.72H5.016V6.848H0.696V4.952H5.016V0.271999H7.104V4.952H11.4V6.848Z"
      fill="currentColor"
    />
  </svg>
</template>
